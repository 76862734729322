import { api } from '../axios'

const SettingsService = (() => {
  const toggleEnableAfterCallTags = async () => {
    const res = await api.patch(`/organization/after-call-tags/toggle`)
    return res
  }

  const updatePostCallDefaultSurvey = async id => {
    const res = await api.patch(`/organization/default-survey`, {
      survey: id
    })
    return res
  }

  return {
    toggleEnableAfterCallTags,
    updatePostCallDefaultSurvey
  }
})()

export default SettingsService
