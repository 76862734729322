<template>
  <div class="page" id="afterCall">
    <div class="mb-1 card">
      <div class="d-flex align-items-center">
        <b-form-checkbox
          size="lg"
          v-model="enableCallSummary"
          @change="save"
          :disabled="enableCallSummaryLoading"
        >
          {{ $t('settings.callSettings.afterCall.callSummary') }}
        </b-form-checkbox>
        <half-circle-spinner
          slot="loading"
          :animation-duration="1500"
          :size="25"
          color="#74b9ff"
          v-if="enableCallSummaryLoading"
          class="mx-3"
        />
      </div>
    </div>
    <div class="mb-1 card">
      <router-link
        to="/tags"
        class="d-flex justify-content-between align-items-center"
      >
        <h3 class="m-0">
          {{ $t('settings.callSettings.afterCall.tags') }}
        </h3>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SettingsService from '../../../../services/settings.service'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  name: 'Settings',
  components: {
    HalfCircleSpinner
  },
  computed: {
    ...mapGetters(['currentTenant'])
  },
  data() {
    return {
      enableCallSummary: true,
      enableCallSummaryLoading: false
    }
  },
  methods: {
    ...mapActions(['fetchCurrentUser']),
    async save() {
      try {
        this.enableCallSummaryLoading = true
        const res = await SettingsService.toggleEnableAfterCallTags()
        this.fetchCurrentUser()
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage)
      } finally {
        this.enableCallSummaryLoading = false
      }
    }
  },
  watch: {
    currentTenant: {
      immediate: true,
      handler() {
        if (!this.currentTenant) return
        this.enableCallSummary = this.currentTenant.enableAfterCallTags
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
.card {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
